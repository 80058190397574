
/* ============================================== */
/* ============== Globel ================== */
/* ============================================== */

section{
    position: relative;
    height: auto;
    width: 100%;
}
/* ============================================== */
/* ============== Nav ================== */
/* ============================================== */
nav.top-nav{
    height: 100px;
    background-color: #191919;
    border-bottom: 1px solid rgb(49 52 66);
    display: flex;
    align-items: center;
    justify-content: center ;
}
.nav-left-side{
    display: flex;
    align-self: center;
}
.nav-left-side ul {
    display: flex;
    gap: 1.25rem;
    margin-bottom: 0 !important;    
    align-items: center;
}
.nav-left-side ul li{
    list-style: none;
}
.nav-left-side ul li a{
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(145 149 171);
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .2s;
}
.nav-left-side ul li a:hover{
    color: #c6c8d7;
}
/* ============================================== */
/* ============== Mobile Nav ================== */
/* ============================================== */
.mobile-menu{
    position: fixed;
    width: 340px;
    height: 100%;
    background-color: rgb(19 16 16);
    z-index: 999;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateX(100%); /* Hidden position */
    opacity: 0; /* Initially hidden */
}
.mobile-menu.open {
    transform: translateX(0); /* Show position */
    opacity: 1; /* Fully visible */
}
.mobile-menu ul{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.mobile-menu ul a{
    padding: 12px 20px;
    display: block;
    width: 100%;
    font-size: 16px;
    border-bottom: 1px solid #655f57;
    color: #fff;
    opacity: 0.6;
    
}
.mobile-menu .close-btn{
    height: 25px;
    position: absolute;
    right: 12px;
    top: 14px;
    opacity: 0.6;
    cursor: pointer;
}
.mobile-menu ul a:hover, .mobile-menu .close-btn:hover{
    opacity: 1;
}
/* ============================================== */
/* ============== Hero ================== */
/* ============================================== */
.hero{
    /* height: calc(100vh - 80px); */
    height: calc(100vh - 100px);
    background: linear-gradient(rgb(19, 21, 31) -4.84%, rgb(29, 28, 47) 34.9%, rgb(33, 32, 54) 48.6%, rgb(40 44 62) 66.41%, #414a6b 103.41%, #5f73b6 132.18%);
    
    background-size: 100% 100%;
}
.hero h1{
    font-size: 5.2rem;
    line-height: 3.5rem;
    font-weight: 700;
    color: #fff;
}
.hero p{
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
    color: #fff;
}
@media (max-width: 763px){
    .hero h1 {
        font-size: 3.8rem;
    }
    .hero p {
        font-size: 1.2rem;
        line-height: 1.9rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        text-align: center;
    }
    .hero img{
        width: 400px;
        margin: 0 auto;
        margin-top: 20px;
    }
}
@media (max-width: 500px){
    .hero h1 {
        font-size: 3rem;
    }
    .hero p{
        margin-top: 0.5rem;
        margin-bottom: 1.4rem;
    }
    .hero img{
        width: 100%;
    }
}
/* ============================================== */
/* ============== Hero ================== */
/* ============================================== */
.features{
background: rgb(33 31 31);
padding: 80px 0;
}
h1{
    font-size: 3rem;
    line-height: 3.5rem;
    font-weight: 700;
    color: #fff;
}
.feature-card{ 
    padding: 2rem 1.5rem ;
    background-color: rgb(28 24 24);
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .2s;
    border-radius: .8rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.feature-card:hover{
    --tw-shadow: 0px 32px 72px rgba(0, 0, 0, .16) !important;
    --tw-shadow-colored: 0px 32px 72px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    transform: translate(var(--tw-translate-x),  -.5rem) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}   
.feature-card h2{
    font-size: 22px;
    line-height: 2.5rem;
    /* margin: 1.25rem 0 1rem !important; */

}
/* ============================================== */
/* ============== How It Works ================== */
/* ============================================== */
.how-it-works{
    background: rgb(28 24 24);
    padding: 80px 0;
}    
.how-it-works p{
    font-size: 19px;
    line-height: 30px;
}
/* ============================================== */
/* ============== Get Started ================== */
/* ============================================== */
.get-started{
    padding: 80px 0;
    background-size: 100% auto;
    background-position: bottom;
    background-repeat: no-repeat;
}  

.get-started::before{
    content: "";
    background: linear-gradient(to right, #FF7844, #190802);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}
.get-started .container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}
    .get-started p{
        font-size: 19px;
        line-height: 25px;
    }
    .get-started p.help-text{
        background: #272934;
        padding: 8px 20px;
        border-radius: 6px;
    }
    .get-started p a{
        text-decoration: underline;
        /* color: #000; */
    }
    @media (min-width: 1600px) {
        .get-started{
            background-position: center;
        }  
        
    }
/* ============================================== */
/* ============== Get Started ================== */
/* ============================================== */
.footer{
    background: #000;
    padding: 60px 0 0px;
}  
.footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 25px;
}
.footer p{
    color: #9195ab;
    font-size: 15px;
    line-height: 25px;
}
.footer .p1{
    max-width: 300px;
}
.footer ul li a{
    width: 100%;
    padding: 8px 0;
    display: block;
    color: rgb(155, 157, 159);
    font-size: 15px;
}
.footer h6{
    color: #f2f4fb;
    font-size: 15px;
    line-height: 25px;
    max-width: 260px;
}
.sub-footer{
    margin-top: 40px;
    border-top: 1px dashed rgb(33 31 31);
    padding: 20px 0 25px;
}